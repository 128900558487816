<template>
    <div>
        <h1>Are you at Dirty Frank's Right Now?</h1>
    </div>
    <div class="checkboxDiv">
        <div>
            <input
                type="checkbox"
                id="pissPatrolCheckbox"
                v-model="pissPatrolAtFranks"
                name="PissPatrol"
                v-on:click="updateIsAtFranks"
                value="pissPatrolAtFranks"
            />
            <label v-if="!pissPatrolAtFranks" for="pissPatrolCheckbox"
                >Piss Patrol is not at Dirty Frank's</label
            >
            <label v-if="pissPatrolAtFranks" for="pissPatrolCheckbox"
                >Piss Patrol is currently at Dirty Frank's</label
            >
        </div>
        <div>
            <input
                type="checkbox"
                id="phatPisserCheckbox"
                v-model="phatPissersAtFranks"
                name="PhatPissers"
                v-on:click="updateIsAtFranks"
                value="phatPissersAtFranks"
            />
            <label v-if="!phatPissersAtFranks" for="phatPisserCheckbox"
                >Phat Pissers are not at Dirty Frank's</label
            >
            <label v-if="phatPissersAtFranks" for="phatPisserCheckbox"
                >Phat Pissers are currently at Dirty Frank's</label
            >
        </div>
    </div>
    <div>
        <h2 v-if="pissPatrolAtFranks && phatPissersAtFranks">
            Time for a City Wide!
        </h2>
    </div>
</template>

<script>
import DatabaseService from "../services/DatabaseService";

export default {
    name: "HomePage",
    props: {},
    data() {
        return {
            pissPatrolAtFranks: false,
            phatPissersAtFranks: false,
        };
    },
    mounted() {
        this.$nextTick(async function () {
            try {
                let atFranks = await DatabaseService.getAll();
                console.log(atFranks);

                if (atFranks) {
                    if (atFranks.PissPatrol) {
                        console.log("piss patrol there");
                        console.log(atFranks.PissPatrol);
                        this.pissPatrolAtFranks =
                            atFranks.PissPatrol.Is_At_Franks;
                    }
                    if (atFranks.PhatPissers) {
                        console.log("phat pissers there");
                        console.log(atFranks.PhatPissers);
                        this.phatPissersAtFranks =
                            atFranks.PhatPissers.Is_At_Franks;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        });
    },
    methods: {
        updateIsAtFranks: function (event) {
            console.log(event);
            console.log(DatabaseService);
            DatabaseService.update(event.target.name, event.target.checked);
        },
    },
};
</script>

<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
.checkboxDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
</style>
