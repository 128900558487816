import { initializeApp } from 'firebase/app'
import { getDatabase } from "firebase/database"

const firebaseConfig = {
    apiKey: "AIzaSyDeTo6Sff8kg7-Gf5ly_e8zjRK-Us03MyU",
    authDomain: "yall-at-franks-take-2.firebaseapp.com",
    projectId: "yall-at-franks-take-2",
    storageBucket: "yall-at-franks-take-2.appspot.com",
    messagingSenderId: "577268636756",
    appId: "1:577268636756:web:af00d7a20cbca89fa3e1c1",
    measurementId: "G-K87WRVL43T",
    databaseURL: "https://yall-at-franks-take-2-default-rtdb.firebaseio.com/",
}

const app = initializeApp(firebaseConfig)

const db = getDatabase(app, "https://yall-at-franks-take-2-default-rtdb.firebaseio.com/")

export default db