import db from "../firebase"
import { ref, set, child, get } from "firebase/database"

class DatabaseService {
    async getAll() {
        const dbRef = ref(db)

        try {
            const users = await get(child(dbRef, 'users/'))

            if (users.exists()) {
                console.log(users.val())
                return users.val()
            } else {
                return undefined
            }
        } catch (error) {
            console.error(error)
            return undefined
        }
    }

    create(tutorial) {
        return db.push(tutorial);
    }

    update(user, value) {
        return set(ref(db, '/users/' + user), {
            Is_At_Franks: value,
        });
    }

    delete(key) {
        return db.child(key).remove()
    }

    deleteAll() {
        return db.remove()
    }
}

export default new DatabaseService()