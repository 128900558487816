<template>
    <div class="home">
        <img
            alt="Vue logo"
            src="../assets/DirtyFranksOutside.jpg"
            style="height: 400px"
        />
        <HomePage />
    </div>
</template>

<script>
// @ is an alias to /src
import HomePage from "@/components/HomePage.vue";

export default {
    name: "Home",
    components: {
        HomePage,
    },
};
</script>
